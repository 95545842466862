import type { NextPage } from "next";

import { useState } from "react";
import useSWR from "swr";

import {
  AddFab,
  CCRFab,
  DecisionAlert,
  ExcelExportButton,
  Link,
  Opportunities,
  ResourcePage,
  ResourceSearchForm,
  ScrollToTopFab,
  Text
} from "@/components";
import { useProtectedRoute, useWindowScrollableInfiniteList } from "@/hooks";
import { getQueryString } from "@/lib/fetch-json";
import { Report } from "@/types/misc";
import { ApprovalType, Decision } from "@/types/models";
import { Resource } from "@/types/resources";
import { Box, Button, css, Flex } from "@modulz/design-system";

import type { Approval } from "@/types/models";
const box = css({
  borderBottom: "1px solid #eee",
  px: "$3",
  py: "$3",
  "@bp3": { px: "$6" },
});

type PendingOpportunityProps = {
  approval: Approval;
};

function PendingOpportunity(props: PendingOpportunityProps) {
  const { decision, type, opportunity } = props.approval;
  const { id, client, title } = opportunity!;

  return (
    <Box className={box()}>
      <Flex direction="column" gap="3">
        <DecisionAlert {...{ decision, type }} direction="row">
          {ApprovalType.GO_NO_GO}
        </DecisionAlert>

        <Flex align="start" direction="column" gap="1">
          <Link href={`/opportunities/${id}`} variant="blue">
            <Text size="4">{title}</Text>
          </Link>
          <Text variant="gray">{client?.organization_name}</Text>
        </Flex>
      </Flex>
    </Box>
  );
}

const HomePage: NextPage = () => {
  const isReady = useWindowScrollableInfiniteList();
  const user = useProtectedRoute();

  const { data: approvals } = useSWR<Approval[]>(
    user
      ? `/api/approvals${getQueryString({
          decision: Decision.PENDING,
          user_id: user.id,
        })}`
      : null
  );
  const hasPendingApprovals = Boolean(approvals && approvals.length > 0);
  const [isPendingApprovalsDismissed, setIsPendingApprovalsDismissed] =
    useState<boolean>(false);
  const isPendingApprovalsVisible =
    hasPendingApprovals && !isPendingApprovalsDismissed;

  function dismissPendingApprovals() {
    setIsPendingApprovalsDismissed(true);
  }

  if (!user || !approvals) {
    return null;
  }

  return (
    <>
      <ResourcePage
        exportButton={
          <ExcelExportButton
            id={Report.OPPORTUNITIES}
            resource={Resource.OPPORTUNITIES}
          />
        }
        search={
          !isPendingApprovalsVisible && (
            <ResourceSearchForm placeholder="Search Opportunities..." />
          )
        }
        title={
          isPendingApprovalsVisible ? "Pending Opportunities" : "Opportunities"
        }
      >
        {isReady &&
          (isPendingApprovalsVisible ? (
            <Flex direction="column" gap="5">
              <Button onClick={dismissPendingApprovals} size="2">
                View All Opportunities
              </Button>

              <ul>
                {approvals.map((approval) => (
                  <PendingOpportunity key={approval.id} {...{ approval }} />
                ))}
              </ul>
            </Flex>
          ) : (
            <Opportunities />
          ))}
      </ResourcePage>

      <ScrollToTopFab />
      <CCRFab href="/ccrs/add?return_to=/" title="Add CCR" />
      <AddFab href="/opportunities/add?return_to=/" title="Add Opportunity" />
    </>
  );
};

export default HomePage;
